import BasicLayout from "main/layouts/BasicLayout/BasicLayout";

export default function HomePage() {  
  return (
    <div data-testid={"LoadingPage-main-div"}>
      <BasicLayout>
        
      </BasicLayout>
    </div>
  )
}
